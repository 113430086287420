import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import './App.css'
import Icons from './components/Icons';
import HomePage from './pages/HomePage';
import EarnPage from './pages/EarnPage';
import LeadersPage from './pages/LeadersPage';
import FriendsPage from './pages/FriendsPage';
import QuestsPage from './pages/QuestsPage';
import WebApp from '@twa-dev/sdk';

import BNBLogo from './assets/BNB.svg'
import LoadingImage from './assets/loader-bg.webp'
import dailyRewardImage from './assets/daily-reward.webp'
import walletImage from './assets/cgpt-wallet.webp'
import LogoImage from './assets/logo-tapcgpt.webp'
import { useGSAP } from '@gsap/react';
import gsap, { TextPlugin } from 'gsap/all';
import { CountUp } from 'countup.js';
import { boostsVal, ENERGY_LEVEL_MULTIPLIER, jobsVal, levelsVal, maxFullEnergy, maxSuperSpeed, refRewardBasic, refRewardPremium, sampleUsers } from './constants';
import CloseButton from './components/CloseButton';
import getDeviceName, { clamp, scrollParentToChild } from './scripts/utils';
import CardsModal from './components/CardsModal';
import QRCode from './pages/QRCode';
import ScrambleText from 'scramble-text';
import { UserContext } from './contexts/userContext';
import ReactGA from 'react-ga4';
import ProfilePage from './pages/ProfilePage';
import { Player } from '@lottiefiles/react-lottie-player';
const TRACKING_ID = "G-DG9Y6BFYGF";


WebApp.ready();
// console.log(WebApp.version.localeCompare("6.9", undefined, { numeric: true, sensitivity: 'base' }));
function App() {
  const { loading, isConnected, isMobile, profile, cards, level, energyLevel, isAllowed, profit, dailyRewards, turbo, activateTurbo, rewardsHandler, tapHandler, cardHandler, boostHandler, taskHandler, profileHandler, leaderHandler, userId } = useContext(UserContext);

  const [name, setName] = useState("User");
  useLayoutEffect(() => {

    if (!WebApp.isExpanded) {
      WebApp.expand();
    }

    if (WebApp.isVerticalSwipesEnabled) {
      WebApp.disableVerticalSwipes();
    }

    if (!WebApp.isClosingConfirmationEnabled) {
      WebApp.enableClosingConfirmation()
    }



  }, [])

  useLayoutEffect(() => {
    if (window.location.hash) {
      const currentURL = window.location.href;
      const urlWithoutHash = currentURL.split('#')[0];
      window.history.replaceState({}, document.title, urlWithoutHash);
    }
  }, [])

  useEffect(() => {
    if (WebApp.initDataUnsafe.user) {
      if (WebApp.initDataUnsafe.user.username) {
        setName(WebApp.initDataUnsafe.user.username);
      } else {
        setName(userId);
      }
    } else {
      setName(userId);
    }
  }, [userId])

  useEffect(() => {

    ReactGA.initialize(TRACKING_ID, {
      gaOptions: {
        device_name: getDeviceName(),
        page_location: window.location.href,
      }
    });
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "TapCGPT" });


  }, [])

  useEffect(() => {
    if(activateTurbo === true){
      cgpt.current.turboCount = 3 - turbo;
    }
  }, [activateTurbo])






  const mainContainer = useRef();
  const [activeTab, setActiveTab] = useState("Home");
  const loading_tl = useRef();
  const loadingText = useRef();
  const loadingText_tl = useRef();
  const [renderProfile, setRenderProfile] = useState(false);

  const handleTabSwitching = (tabName) => {
    setActiveTab(tabName);
    ReactGA.event("navigation", {
      category: "Navigation",
      page: `${tabName} page`,
      label: `User tapped on the ${tabName} Icon`, // optional
      userId: userId,
      username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
      device_name: getDeviceName(),
      locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
  };

  useLayoutEffect(() => {
    gsap.registerPlugin(TextPlugin);
  }, []);

  const cgpt = useRef({
    value: 0,
    taps: 0,
    energy: 500,
    energyCost: 1,
    tapsCache: 0,
    pointsCache: 0,
    turboTapsCache: 0,
    turboPointsCache: 0,
    tempTaps: 0,
    tempPoints: 0,
    tempTTaps: 0,
    tempTPoints: 0,
    energylimit: 500,
    multiplier: 1,
    rechargeSpeed: 1,
    coinstolevelup: 500,
    level: level,
    levelPercent: 0,
    jobLevel: 0,
    jobName: "Unemployed AI",
    jobProfit: 0,
    turboCount: 0,
    fullEnergyCount: 3,
    totalReferrals: 0,
    multitapLevel: 1,
    energyLimitLevel: 1,
    rechargeSpeedLevel: 1,
    tapbotEnabled: false,
    claimedCards: [0],
    unlockedCards: [0]
  })
  // sessionStorage.setItem('levelup', "true");
  const levelup = useRef(true);
  const findLevel = (value) => levelsVal.findIndex((level) => (value >= level.min && value < level.max));
  const modalFunc = useRef(0);
  const tempCard = useRef({});
  const inviteLink = useRef();
  const scramble = useRef();
  const walletAddress = useRef();
  const warningMessage = useRef();
  const scrambleModalTitle = useRef();
  const [reanimateScramble, setReanimateScramble] = useState(false);
  const [modalTitle, setModalTitle] = useState("Welcome");
  const [modalCardLevel, setModalCardLevel] = useState(0);
  const [modalType, setModalType] = useState("Tap CGPT");
  const [buttonLabel, setButtonLabel] = useState("Continue");
  const [modalDescription, setModalDescription] = useState("Get ready to build your AI startup with just a tap!");
  const [modalQuantity, setModalQuantity] = useState("");
  const [modalQuantityText, setModalQuantityText] = useState("");
  const [rerenderCards, setRerenderCards] = useState(false);
  const [rerenderTasks, setRerenderTasks] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const { contextSafe } = useGSAP({ dependencies: [isMobile], scope: mainContainer, revertOnUpdate: true });
  const processingTaps = useRef(false);
  const processingTurboTaps = useRef(false);
  const processingTimer = useRef(null);
  const lastdata = useRef({
    taps: 0,
    points: 0,
    tTaps: 0,
    tPoints: 0
  })


  const debounce = (callback) => {

    return (...args) => {
      if (processingTimer.current) {
        clearTimeout(processingTimer.current);
        processingTimer.current = null;
      }
      processingTimer.current = setTimeout(() => {
        callback(...args);
      }, 1000);
    };
  }

  const tapHandling = () => {

    if (cgpt.current.energyCost == 0) {
    //If the tap stops when it is still in turbo mode
      if (cgpt.current.turboTapsCache > 0 && cgpt.current.turboPointsCache > 0) {
        let result = tapHandler(cgpt.current.turboTapsCache, cgpt.current.turboPointsCache, true);
        // cgpt.current.tempTTaps = cgpt.current.turboTapsCache; // Save the turbo taps cache to a temporary variable
        // cgpt.current.tempTPoints = cgpt.current.turboPointsCache; // Save the turbo points cache to a temporary variable
        localStorage.setItem('localTaps', 0);
        localStorage.setItem('localPoints', 0);
        cgpt.current.turboTapsCache = 0; // Reset the turbo taps cache
        cgpt.current.turboPointsCache = 0; // Reset the turbo points cache
        result.then((res) => {
          processingTaps.current = false;
          // cgpt.current.tempTTaps = 0; // Reset the temporary turbo taps cache
          // cgpt.current.tempTPoints = 0;  // Reset the temporary turbo taps cache
          // console.log("processing taps: ", processingTaps.current)
          ReactGA.event('earn_virtual_currency', {
            category: "Tap",
            label: "User Tapped",
            virtual_currency_name: 'tCGPT',
            value: cgpt.current.turboPointsCache,
            userId: userId,
            username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
            device_name: getDeviceName(),
            locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
        }).catch((e) => {
          processingTaps.current = false;
          // lastdata.current.tTaps = lastdata.current.tTaps + cgpt.current.tempTTaps;  // Add the temporary turbo taps cache to the cloud storage variable
          // lastdata.current.tPoints = lastdata.current.tPoints + cgpt.current.tempTPoints; // Add the temporary turbo points cache to the cloud storage variable
          // //save lastdata.current to cloud storage 
          // if (WebApp.isVersionAtLeast("6.9")) {
          //   WebApp.CloudStorage.setItem("last_data", JSON.stringify(lastdata.current)); // Save the cloud storage variable
          // }
        })

      }
    } else {
      //If the tap stops when it is not in turbo mode
      if (cgpt.current.tapsCache > 0 && cgpt.current.pointsCache > 0) {
        let result = tapHandler(cgpt.current.tapsCache, cgpt.current.pointsCache, false);
        // cgpt.current.tempTaps = cgpt.current.tapsCache; // Save the taps cache to a temporary variable
        // cgpt.current.tempPoints = cgpt.current.pointsCache; // Save the points cache to a temporary variable
        localStorage.setItem('localTaps', 0);
        localStorage.setItem('localPoints', 0);
        cgpt.current.tapsCache = 0; // Reset the taps cache
        cgpt.current.pointsCache = 0;; // Reset the points cache
        result.then((res) => {
          // console.log(res.data)
          // cgpt.current.tempTaps = 0; // Reset the temporary taps cache on success
          // cgpt.current.tempPoints = 0; // Reset the temporary points cache on success
          processingTaps.current = false;
          // console.log("processing taps: ", processingTaps.current)
          ReactGA.event('earn_virtual_currency', {
            category: "Tap",
            label: "User Tapped",
            virtual_currency_name: 'tCGPT',
            value: cgpt.current.pointsCache,
            userId: userId,
            username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
            device_name: getDeviceName(),
            locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })

        }).catch((e) => {

          processingTaps.current = false;
          // lastdata.current.taps = lastdata.current.taps + cgpt.current.tempTaps;  // Add the temporary taps cache to the cloud storage variable
          // lastdata.current.points = lastdata.current.points + cgpt.current.tempPoints; // Add the temporary points cache to the cloud storage variable
          // //save lastdata.current to cloud storage
          // if (WebApp.isVersionAtLeast("6.9")) {
          //   WebApp.CloudStorage.setItem("last_data", JSON.stringify(lastdata.current)); // Save the cloud storage variable
          // }
        })

        if (cgpt.current.turboTapsCache > 0 && cgpt.current.turboPointsCache > 0) {
          // If there were remaining turbo taps and points values when the user stops tapping (happens when turbo modes finishes and user stops tapping only when its back to normal)
          //This is the same code as the turbo taps and points tap handler call
          let result2 = tapHandler(cgpt.current.turboTapsCache, cgpt.current.turboPointsCache, true);

          // cgpt.current.tempTTaps = cgpt.current.turboTapsCache;
          // cgpt.current.tempTPoints = cgpt.current.turboPointsCache;
          cgpt.current.turboTapsCache = 0;
          cgpt.current.turboPointsCache = 0;
          result2.then((res) => {
            // console.log("processing taps: ", processingTaps.current)
            // console.log(res.data)
            processingTaps.current = false;
            // cgpt.current.tempTTaps = 0;
            // cgpt.current.tempTPoints = 0;
            ReactGA.event('earn_virtual_currency', {
              category: "Tap",
              label: "User Tapped",
              virtual_currency_name: 'tCGPT',
              value: cgpt.current.turboPointsCache,
              userId: userId,
              username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
              device_name: getDeviceName(),
              locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
          }).catch((e) => {
            processingTaps.current = false;
            // lastdata.current.tTaps = lastdata.current.tTaps + cgpt.current.tempTTaps;
            // lastdata.current.tPoints = lastdata.current.tPoints + cgpt.current.tempTPoints;
            // //save lastdata.current to cloud storage
            // if (WebApp.isVersionAtLeast("6.9")) {
            //   WebApp.CloudStorage.setItem("last_data", JSON.stringify(lastdata.current));
            // }
          });
        }
        // console.log(cgpt.current.tapsCache, cgpt.current.pointsCache, cgpt.current.turboTapsCache, cgpt.current.turboPointsCache)

      }
    }


  }
  const processTaps = debounce(() => tapHandling());


  useLayoutEffect(() => {
    const recharge = setInterval(() => {
      if (processingTaps.current) {
        cgpt.current.energy = cgpt.current.energy + 1;
        cgpt.current.energy = clamp(cgpt.current.energy, 0, cgpt.current.energylimit);
      }
    }, 1000)
    return () => clearInterval(recharge);
  }, [])



  const handleValChange = contextSafe((type, val = {}) => {

    if (type == "none") {
    } else if (type == "add coin") {
      if (cgpt.current.energy >= (cgpt.current.energyCost * cgpt.current.multitapLevel) && Object.hasOwn(val, 'add')) {

        cgpt.current.value = cgpt.current.value + (val.add * cgpt.current.multitapLevel * cgpt.current.multiplier);
        cgpt.current.energy = cgpt.current.energy - (val.add * cgpt.current.multitapLevel * cgpt.current.energyCost);
        cgpt.current.taps = cgpt.current.taps + (val.add * cgpt.current.multiplier);

        if (cgpt.current.energyCost == 1) {
          cgpt.current.tapsCache = cgpt.current.tapsCache + (val.add * cgpt.current.multiplier);
          cgpt.current.pointsCache = cgpt.current.pointsCache + (val.add * cgpt.current.multitapLevel * cgpt.current.multiplier);
          localStorage.setItem("localTaps", cgpt.current.tapsCache);
          localStorage.setItem("localPoints", cgpt.current.pointsCache);
        } else {
          cgpt.current.turboTapsCache = cgpt.current.turboTapsCache + (val.add * cgpt.current.multiplier);
          cgpt.current.turboPointsCache = cgpt.current.turboPointsCache + (val.add * cgpt.current.multitapLevel * cgpt.current.multiplier);
          localStorage.setItem("localTaps", cgpt.current.turboTapsCache);
          localStorage.setItem("localPoints", cgpt.current.turboPointsCache);
        }
        processingTaps.current = true;
        processTaps();
        // console.log(cgpt.current.tapsCache, cgpt.current.pointsCache, cgpt.current.turboTapsCache, cgpt.current.turboPointsCache)
        if ((findLevel(cgpt.current.value)) > cgpt.current.level) {
          cgpt.current.level = findLevel(cgpt.current.value);
          if (cgpt.current.energyCost == 1) {
            handleChangeModal("level up", { level: cgpt.current.level });
          }
        }
        cgpt.current.coinstolevelup = levelsVal[cgpt.current.level].max;
        cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;

      }
    } else if (type == "refill energy") {




      if (cgpt.current.fullEnergyCount > 0) {
        boostHandler("Full Energy");
        cgpt.current.energy = cgpt.current.energylimit;
        cgpt.current.fullEnergyCount = cgpt.current.fullEnergyCount - 1;

        ReactGA.event("boosts", {
          category: "Boosts",
          boost_type: "Full Energy",
          label: "User tapped on Refill Energy Boost", // optional
          value: cgpt.current.fullEnergyCount,
          userId: userId,
          username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
          device_name: getDeviceName(),
          locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      }

    } else if (type == "turbo") {


      if (cgpt.current.turboCount > 0) {

        boostHandler("Turbo");
        cgpt.current.multiplier = 5;
        cgpt.current.energyCost = 0;

        const turbotimer = setTimeout(() => {
          cgpt.current.multiplier = 1;
          cgpt.current.energyCost = 1;
        }, 20000);

        cgpt.current.turboCount = cgpt.current.turboCount - 1;

        ReactGA.event("boosts", {
          category: "Boosts",
          boost_type: "Turbo",
          label: "User tapped on Turbo Boost", // optional
          value: cgpt.current.turboCount,
          userId: userId,
          username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
          device_name: getDeviceName(),
          locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      }

    } else if (type == "multitap") {
      if (cgpt.current.multitapLevel <= boostsVal.multitap.length) {
        boostHandler("Multitap");
        cgpt.current.value = cgpt.current.value - boostsVal.multitap[cgpt.current.multitapLevel - 1];
        cgpt.current.coinstolevelup = levelsVal[cgpt.current.level].max;
        cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;
        cgpt.current.multitapLevel = cgpt.current.multitapLevel + 1;
        cgpt.current.coinspertap = cgpt.current.multitapLevel;
        ReactGA.event("boosts", {
          category: "Boosts",
          boost_type: "Multitap",
          current_level: cgpt.current.multitapLevel,
          cost: boostsVal.multitap[cgpt.current.multitapLevel - 1],
          label: "User tapped on Multitap Boost",
          userId: userId,
          username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
          device_name: getDeviceName(),
          locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      }


    } else if (type == "change energy limit") {


      if (cgpt.current.energyLimitLevel <= boostsVal.energyLimit.length) {
        boostHandler("Energy Limit");
        cgpt.current.value = cgpt.current.value - boostsVal.energyLimit[cgpt.current.energyLimitLevel - 1];
        cgpt.current.coinstolevelup = levelsVal[cgpt.current.level].max;
        cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;
        cgpt.current.energyLimitLevel = cgpt.current.energyLimitLevel + 1;
        cgpt.current.energylimit = cgpt.current.energyLimitLevel * ENERGY_LEVEL_MULTIPLIER;
        ReactGA.event("boosts", {
          category: "Boosts",
          boost_type: "Energy Limit",
          current_level: cgpt.current.energyLimitLevel,
          cost: boostsVal.energyLimit[cgpt.current.energyLimitLevel - 1],
          label: "User tapped on Energy Limit Boost",
          userId: userId,
          username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
          device_name: getDeviceName(),
          locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        // cgpt.current.energy = cgpt.current.energy + ENERGY_LEVEL_MULTIPLIER;
      }


    } else if (type == "change recharge speed") {

      // boostHandler("Recharge Speed");
      cgpt.current.value = cgpt.current.value - boostsVal.rechargeSpeed[cgpt.current.rechargeSpeedLevel - 1];
      cgpt.current.level = findLevel(cgpt.current.value);
      cgpt.current.coinstolevelup = levelsVal[findLevel(cgpt.current.value)].max;
      cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;
      cgpt.current.rechargeSpeedLevel = cgpt.current.rechargeSpeedLevel + 1;
      cgpt.current.rechargeSpeedLevel = clamp(cgpt.current.rechargeSpeedLevel, 0, boostsVal.rechargeSpeed.length);
      cgpt.current.rechargeSpeed = cgpt.current.rechargeSpeed + 1;
      ReactGA.event("boosts", {
        category: "Boosts",
        boost_type: "Recharge Speed",
        current_level: cgpt.current.rechargeSpeedLevel,
        cost: boostsVal.rechargeSpeed[cgpt.current.rechargeSpeedLevel - 1],
        label: "User tapped on Recharge Speed Boost",
        userId: userId,
        username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
        device_name: getDeviceName(),
        locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })

    } 
    else if (type == "update val") {
      if (Object.hasOwn(val, 'points') && cgpt.current.value < val.points && processingTaps.current == false) { // NEED TO CHECK IMPACT OF processingTaps
      //if (Object.hasOwn(val, 'points') && cgpt.current.value < val.points) {

        cgpt.current.value = val.points;
        cgpt.current.coinstolevelup = levelsVal[cgpt.current.level].max;
        cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;
      }
      if (Object.hasOwn(val, 'tapbotEnabled')) {
        cgpt.current.tapbotEnabled = val.tapbotEnabled;
      }
      if (Object.hasOwn(val, 'taps') && cgpt.current.taps < val.taps && processingTaps.current == false) {
        cgpt.current.taps = val.taps;
      }
      if (Object.hasOwn(val, 'level') && val.level > cgpt.current.level && processingTaps.current == false) {
        // console.log("check level")

        cgpt.current.level = val.level;
        cgpt.current.coinstolevelup = levelsVal[cgpt.current.level].max;
        cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;
      }

      if (Object.hasOwn(val, 'energyLevel') && processingTaps.current == false) {

        cgpt.current.energy = val.energyLevel;
        // cgpt.current.energy = clamp(cgpt.current.energy, 0, cgpt.current.energylimit);
      }

      if (Object.hasOwn(val, 'energyLimitLevel')) {
        cgpt.current.energyLimitLevel = val.energyLimitLevel;
        cgpt.current.energylimit = val.energyLimitLevel * ENERGY_LEVEL_MULTIPLIER;
        // console.log("Changing energy limit level: ", cgpt.current.energylimit)
        // cgpt.current.energy = Math.floor(cgpt.current.energylimit * rateofchange);
      }
      if (Object.hasOwn(val, 'multitapLevel')) {
        cgpt.current.multitapLevel = val.multitapLevel;

      }
      if (Object.hasOwn(val, 'rechargeSpeedLevel')) {
        cgpt.current.rechargeSpeedLevel = val.rechargeSpeedLevel;

      }
      if (Object.hasOwn(val, 'turbo') && cgpt.current.turboCount > maxSuperSpeed - val.turbo) {
        cgpt.current.turboCount = maxSuperSpeed - val.turbo;
        // console.log(cgpt.current.turboCount);

      }
      if (Object.hasOwn(val, 'fullEnergy') && cgpt.current.fullEnergyCount > maxFullEnergy - val.fullEnergy) {
        cgpt.current.fullEnergyCount = maxFullEnergy - val.fullEnergy;
        // console.log(cgpt.current.fullEnergyCount);
      }

      if (Object.hasOwn(val, 'jobLevel')) {
        if (val.jobLevel) cgpt.current.jobLevel = val.jobLevel;
        // console.log(cgpt.current.jobLevel)
      }
      if (Object.hasOwn(val, 'jobName')) {
        if (val.jobName) cgpt.current.jobName = val.jobName;
      }
      if (Object.hasOwn(val, 'jobProfit')) {
        if (val.jobProfit) cgpt.current.jobProfit = val.jobProfit;
      }

    } else if (type == "task rewards") {
      // taskHandler(taskObject.current.userId, taskObject.current.id);
      cgpt.current.value = cgpt.current.value + (taskObject.current.rewards);

      cgpt.current.level = findLevel(cgpt.current.value);
      cgpt.current.coinstolevelup = levelsVal[findLevel(cgpt.current.value)].max;
      cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;

    } else if (type == "task rewards no handler") {
      if (Object.hasOwn(val, 'id')) {
        taskHandler("Claim", userId, val.id);

        ReactGA.event("quest_completed", {
          category: "Quests",
          quest_name: val.title,
          label: "User tapped on Claim Task Button", // optional
          rewards: val.rewards,
          userId: userId,
          username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
          device_name: getDeviceName(),
          locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })


      }
      // cgpt.current.value = cgpt.current.value + (val.rewards);
      // cgpt.current.level = findLevel(cgpt.current.value);
      // cgpt.current.coinstolevelup = levelsVal[cgpt.current.level].max;
      // cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;

    } else if (type == "claim card") {
      // console.log("Buy Request ready")
      if (cgpt.current.value > tempCard.current.cost) {
        cgpt.current.value = cgpt.current.value - (tempCard.current.cost);
        cgpt.current.coinstolevelup = levelsVal[cgpt.current.level].max;
        cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;
        cardHandler("Buy", tempCard.current.id);
        // console.log("Buy Request Sent");
        ReactGA.event("card_bought", {
          category: "Cards",
          card_name: tempCard.current.name,
          label: "User tapped on Buy Card Button", // optional
          cost: tempCard.current.cost,
          userId: userId,
          username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
          device_name: getDeviceName(),
          locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        setRerenderCards(!rerenderCards);
      }
      tempCard.current = {};
    } else if (type == "select card") {
      if (tempCard.current.status == "inactive") {
        cgpt.current.jobProfit = tempCard.current.profit;
        cgpt.current.jobLevel = tempCard.current.requiredLevel;
        cgpt.current.jobName = tempCard.current.name;
        cardHandler("Activate", tempCard.current.id);
        ReactGA.event("card_activated", {
          category: "Cards",
          card_name: tempCard.current.name,
          label: "User tapped on Select Card Button", // optional
          profit: tempCard.current.profit,
          userId: userId,
          username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
          device_name: getDeviceName(),
          locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        setRerenderCards(!rerenderCards);
      }
    } else if (type == "retrieve profit") {
      ReactGA.event("earn_virtual_currency", {
        category: "Profit",
        action: `User Profit from Job`,
        label: "User viewed the Profit Modal", // optional
        virtual_currency_name: 'tCGPT',
        value: profit,
        userId: userId,
        username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
        device_name: getDeviceName(),
        locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      rewardsHandler("Profit");
      // cgpt.current.value = cgpt.current.value + (val.rewards);
      // cgpt.current.level = findLevel(cgpt.current.value);
      // cgpt.current.coinstolevelup = levelsVal[cgpt.current.level].max;
      // cgpt.current.levelPercent = cgpt.current.value / cgpt.current.coinstolevelup * 100;

    } else if (type == "claim daily") {
      rewardsHandler("Claim Daily Rewards");
      ReactGA.event("earn_virtual_currency", {
        category: "Daily Rewards",
        label: "User claimed the Daily Rewards", // optional
        virtual_currency_name: 'tCGPT',
        value: dailyRewards[dailyRewards.length - 1].reward,
        userId: userId,
        username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
        device_name: getDeviceName(),
        locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    }
  });

  const handleModalFunction = contextSafe((type) => {

    if (type == "none") {

    } else if (type == "close") {

      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });


    } else if (type == "quick close") {

      gsap.to(".modal", { yPercent: 100, duration: 0.02, overwrite: true });
      gsap.to(".modal-boosts", { display: "none", duration: 0.02, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.02, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.02, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.02, overwrite: true });

      gsap.to(".daily-modal-container", { opacity: 0, duration: 0.02, ease: "power2.inOut" });
      gsap.to(".daily-modal", { yPercent: 100, duration: 0.02, ease: "power2.inOut" });
      gsap.to(".wallet-modal-container", { opacity: 0, duration: 0.02, ease: "power2.inOut" });
      gsap.to(".wallet-modal", { yPercent: 100, duration: 0.02, ease: "power2.inOut" });
      gsap.to(".profile-page", { yPercent: 100, autoAlpha: 0, duration: 0.02, ease: "power2.inOut" });

      gsap.to(".boost-page-container", { opacity: 0, duration: 0.02, ease: "power2.inOut" });
      gsap.to(".boost-page", { yPercent: 100, duration: 0.02, ease: "power2.inOut" });


    } else if (type == "turbo") {
      handleValChange("turbo");


      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });


    } else if (type == "refill energy") {
      handleValChange("refill energy");


      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });


    } else if (type == "multitap") {
      handleValChange("multitap");


      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });


    } else if (type == "change energy limit") {
      handleValChange("change energy limit");


      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });


    } else if (type == "change recharge speed") {
      handleValChange("change recharge speed");


      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });


    } else if (type == "invite friends") {

      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });


      navigator.clipboard.writeText(inviteLink.current);
      ReactGA.event("invites", {
        category: "Invites",
        action: `Invite Link copied`,
        label: "User copied the invite link", // optional
        userId: userId,
        username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
        device_name: getDeviceName(),
        locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })

    } else if (type == "task rewards") {

      handleValChange("task rewards");

      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });


    } else if (type == "claim card") {
      handleValChange("claim card");

      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });

    } else if (type == "select card") {
      handleValChange("select card");

      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });

    } else if (type == "retrieve profit") {
      handleValChange("retrieve profit");
      gsap.to(".modal", { yPercent: 100, duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.3, overwrite: true, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "none", autoAlpha: 0, duration: 0.3, overwrite: true });
    } else if (type == "claim daily") {
      handleValChange("claim daily");
      gsap.fromTo(".daily-modal-container", { opacity: 1 }, { opacity: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.fromTo(".daily-modal", { yPercent: 0 }, { yPercent: 100, duration: 0.5, ease: "power2.inOut" });
    } else if (type == "save wallet") {
      // handleValChange("claim daily");
      if (walletAddress.current.value) {
        const validated = profileHandler("Change Profile", { wallet: walletAddress.current.value })
        validated.then(res => {
          if (res.data !== '' && res.data.constructor == Object) {
            handleValChange("save wallet");
            ReactGA.event("add_info", {
              category: "Wallet",
              label: "User added their wallet address", // optional
              value: walletAddress.current.value,
              userId: userId,
              username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
              device_name: getDeviceName(),
              locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
            gsap.fromTo(".wallet-modal-container", { opacity: 1 }, { opacity: 0, duration: 0.5, ease: "power2.inOut" });
            gsap.fromTo(".wallet-modal", { yPercent: 0 }, { yPercent: 100, duration: 0.5, ease: "power2.inOut" });
          }
        })
          .catch(function (error) {
            // handle error
            warningMessage.current.innerText = "Error saving wallet address. Please check your input.";
            // console.log(error);
          })
      } else {
        warningMessage.current.innerText = "Please input your wallet address.";
      }

    }

  });

  const handleButtonEvents = contextSafe((type, object) => {
    object.preventDefault();
    if (type == "none") {

    } else if (type == "main button") {
      switch (modalFunc.current) {
        case 0: //Close the modal
          handleModalFunction("close");
          break;
        case 1:
          handleModalFunction("turbo");
          break;
        case 2:
          handleModalFunction("refill energy");
          break;
        case 3:
          handleModalFunction("multitap");
          break;
        case 4:
          handleModalFunction("change energy limit");
          break;
        case 5:
          handleModalFunction("change recharge speed");
          break;
        case 6:
          handleModalFunction("invite friends");
          break;
        case 7:
          handleModalFunction("task rewards");
          break;
        case 8:
          handleModalFunction("claim card");
          break;
        case 9:
          handleModalFunction("select card");
          break;
        case 10:
          handleModalFunction("retrieve profit");
          break;

        case 11:
          handleModalFunction("claim daily");
          break;
        case 12:
          handleModalFunction("save wallet");
          break;

      }
    } else if (type == "close") {
      handleModalFunction("close");
    }
  })


  const handleProfileView = contextSafe((type, object = {}, link = "") => {
    if (type == "open profile page") {
      profileHandler("Fetch");
      setRenderProfile(true);
      ReactGA.event("navigation", {
        category: "Navigation",
        page: "Profile Page",
        label: "User opened the profile page",
        userId: userId,
        username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
        device_name: getDeviceName(),
        locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      gsap.to(".profile-page", { yPercent: 0, autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
    } else if (type == "close profile page") {
      ReactGA.event("navigation", {
        category: "Navigation",
        page: "Profile Page",
        label: "User closed the profile page",
        userId: userId,
        username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
        device_name: getDeviceName(),
        locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      gsap.to(".profile-page", {
        yPercent: 100, autoAlpha: 0, duration: 0, ease: "power2.inOut", onComplete: () => {
          setRenderProfile(false);
        }
      });
    }
  })

  const handleChangeModal = contextSafe((type, object = {}, link = "") => {

    if (type == "none") {

    } else if (type == "level up") {
      modalFunc.current = 0;
      setModalType("Achievement");
      setModalCardLevel(object.level);
      cgpt.current.unlockedCards.push(object.level);
      setModalTitle("New Card Unlocked!");
      setButtonLabel("Got it");
      setModalDescription(`You leveled up! You can now apply for the ${jobsVal[object.level].name} position.`);
      setModalQuantityText("");
      setModalQuantity("");
      ReactGA.event("level_up", {
        category: "Achievement",
        level: object.level,
        label: "User leveled up",
        userId: userId,
        username: WebApp.initDataUnsafe.user ? WebApp.initDataUnsafe.user.username : null,
        device_name: getDeviceName(),
        locale: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });



    } else if (type == "turbo") {

      modalFunc.current = 1;
      setModalType("Boosts");
      setModalTitle("Turbo");
      setButtonLabel("Get Turbo");
      setModalDescription("Each tap multiplies by 5 for 20 seconds");
      setModalQuantityText("Costs");
      setModalQuantity("Free");
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });


    } else if (type == "refill energy") {


      modalFunc.current = 2;
      setModalType("Boosts");
      setModalTitle("Full Energy");
      setButtonLabel("Refill energy");
      setModalDescription("Instantly restores 100% of energy");
      setModalQuantityText("Costs");
      setModalQuantity("Free");
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });


    } else if (type == "multitap") {
      modalFunc.current = 3;


      setModalType("Boosts");
      setModalTitle("Multitap");
      setButtonLabel("Get it!");
      setModalDescription("Increases amount of coins you can earn per one tap. +1 per level");
      setModalQuantityText("Costs");
      setModalQuantity(`${boostsVal.multitap[cgpt.current.multitapLevel - 1]}`);
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });


    } else if (type == "change energy limit") {
      modalFunc.current = 4;

      setModalType("Boosts");
      setModalTitle("Energy Limit");
      setButtonLabel("Increase");
      setModalDescription("Increases limit of energy. +500 per level");
      setModalQuantityText("Costs");
      setModalQuantity(`${boostsVal.energyLimit[cgpt.current.energyLimitLevel - 1]}`);
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });


    } else if (type == "change recharge speed") {
      modalFunc.current = 5;

      setModalType("Boosts");
      setModalTitle("Recharging Speed");
      setButtonLabel("Increase");
      setModalDescription("Increases speed of recharging. +1 per second for each level");
      setModalQuantityText("Costs");
      setModalQuantity(`${boostsVal.rechargeSpeed[cgpt.current.rechargeSpeedLevel - 1]}`);
      gsap.to(".modal-boosts", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });


    } else if (type == "invite friends") {

      modalFunc.current = 6;
      inviteLink.current = link;
      setModalType("Friends");
      setModalTitle("Invite Friends");
      setButtonLabel("Copy Invite Link");
      setModalDescription(`Invite a friend and get ${refRewardBasic} coins each. ${refRewardPremium} for Telegram Premium users`);
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });


    } else if (type == "task rewards") {
      modalFunc.current = 7;
      taskObject.current = object;
      // console.log(object);
      setModalType("Tasks");
      setModalTitle("Claim Rewards");
      setButtonLabel("Get it!");
      setModalDescription(`${object.category == "leagues" ? "You completed the " + object.title + " league." : "You accomplished the following task: " + object.title}`);
      setModalQuantityText("Reward");
      setModalQuantity(object.rewards);
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "flex", duration: 0.1, ease: "power2.inOut" });

      gsap.to(".modal-card", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });


    } else if (type == "open card") {
      modalFunc.current = 8;
      tempCard.current = object;
      // console.log(object);
      setModalType("Buy Card");
      setModalCardLevel(object.requiredLevel);
      // cgpt.current.unlockedCards.push(object.level);
      setModalTitle(object.name);
      setButtonLabel(object.status == "unlocked" ? cards[object.requiredLevel - 1].status === "unlocked" || cards[object.requiredLevel - 1].status === null ? 'Need Prior Card' : cgpt.current.value < (object.cost) ? `Not enough coins` : `Claim for ${object.cost}` : `Requires Level ${object.requiredLevel}`);
      setModalDescription(object.description);
      setModalQuantityText("Profit");
      setModalQuantity(`${object.profit}/hr`);
      setReanimateScramble(!reanimateScramble);
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: cgpt.current.value < (object.cost) ? "none" : object.status == "unlocked" ? cards[object.requiredLevel - 1].status === "unlocked" || cards[object.requiredLevel - 1].status === null ? "none" : "auto" : "none", opacity: cgpt.current.value < (object.cost) ? 0.5 : object.status == "unlocked" ? cards[object.requiredLevel - 1].status === "unlocked" || cards[object.requiredLevel - 1].status === null ? 0.5 : 1 : 0.5, duration: 0.2 });


    } else if (type == "select card") {
      modalFunc.current = 9;
      tempCard.current = object;
      // console.log(object);
      setModalType("Select Card");
      setModalCardLevel(object.requiredLevel);
      // cgpt.current.unlockedCards.push(object.level);
      setModalTitle(object.name);
      loading ? setButtonLabel(`Disabled`) : setButtonLabel(object.status == "active" ? `Selected` : `Select Job`);
      
      setModalDescription(object.description);
      setModalQuantityText("Profit");
      setModalQuantity(`${object.profit}/hr`);
      setReanimateScramble(!reanimateScramble);
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "none", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: object.status == "inactive" ? "auto" : "none", opacity: object.status == "inactive" ? 1 : 0.5, duration: 0.2 });


    } else if (type == "open daily page") {
      // console.log("opened")
      modalFunc.current = 11;
      const childDiv = document.getElementById(`day-${dailyRewards.length > 0 ? dailyRewards.length - 1 : '0'}`);
      const parentDiv = document.getElementById("daily-container");
      if (childDiv && parentDiv) scrollParentToChild(parentDiv, childDiv);
      gsap.to(".daily-modal-container", { opacity: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".daily-modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
    } else if (type == "close daily page") {
      modalFunc.current = 0;
      gsap.to(".daily-modal-container", { opacity: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".daily-modal", { yPercent: 100, duration: 0.5, ease: "power2.inOut" });
    } else if (type == "open boost page") {
      gsap.to(".boost-page-container", { opacity: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".boost-page", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.fromTo(".boost-div", { xPercent: 0 }, { xPercent: 100, duration: 0.5, ease: "power2.inOut", overwrite: true })
      gsap.fromTo(".boost-div-bg", { xPercent: 0 }, {xPercent: 100, duration: 0, overwrite: true})
      gsap.fromTo(".tap-area", { xPercent: 100 }, { xPercent: 0, duration: 0, ease: "power2.inOut", visibility: 'hidden' })
    } else if (type == "close boost page") {
      gsap.to(".boost-page-container", { opacity: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".boost-page", { yPercent: 100, duration: 0.5, ease: "power2.inOut" });
      gsap.fromTo(".boost-div-bg", { xPercent: 100 }, { xPercent: 100, duration: 1, ease: "power2.inOut", overwrite: false })
      gsap.fromTo(".boost-div", { xPercent: 100 }, { xPercent: 0, duration: 0,overwrite: false })
      gsap.fromTo(".tap-area", { xPercent: 100 }, { xPercent: 0, duration: 0, ease: "power2.inOut", visibility: 'visible'})
    } else if (type == "open wallet page") {
      // console.log("opened")
      modalFunc.current = 12;
      gsap.to(".wallet-modal-container", { opacity: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".wallet-modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
    } else if (type == "close wallet page") {
      modalFunc.current = 0;
      gsap.to(".wallet-modal-container", { opacity: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".wallet-modal", { yPercent: 100, duration: 0.5, ease: "power2.inOut" });
    }


  });



  useGSAP(() => {
    handleModalFunction("quick close");
  }, { dependencies: [activeTab, isMobile], scope: mainContainer })

  useGSAP(() => {
    if (isConnected && profit != 0 && isMobile) {
      modalFunc.current = 10;
      setModalType("Earnings");
      setModalTitle(`You made ${profit} coin${profit > 1 ? 's' : ''} while you're away`);
      setButtonLabel("Got it!");
      setModalDescription("These coins were automatically added to your total balance.");
      setModalQuantityText("Profit");
      setModalQuantity(`${profit}`);
      gsap.to(".modal", { yPercent: 0, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".modal-boosts", { display: "flex", duration: 0.1, ease: "power2.inOut" });
      gsap.to(".modal-card", { display: "none", duration: 0.01, ease: "power2.inOut" });
      gsap.to(".modal-card-price", { display: "none", duration: 0.01, ease: "power2.inOut" });
      gsap.to(".modal-pointer", { pointerEvents: "auto", autoAlpha: 1, duration: 0.5, ease: "power2.inOut" });
      gsap.to(".buttons", { pointerEvents: "auto", opacity: 1, duration: 0.2 });
    }

  }, { dependencies: [isConnected, profit, isMobile], scope: mainContainer, revertOnUpdate: true })

  const [walletShowOnce, setWalletShowOnce] = useState(false);
  useGSAP(() => {
    const walletModalTime = setTimeout(() => {
      if (isConnected && isMobile && profile && profile.wallet == null) {
        if (WebApp.isVersionAtLeast("6.9")) {
          WebApp.CloudStorage.getItem("wallet_date", (e, val) => {
            if (e) {
              // console.log(e)
              handleChangeModal("open wallet page");
              const datenow = new Date();
              WebApp.CloudStorage.setItem("wallet_date", datenow.toString());
            } else {
              if (val) {
                const oldDate = Date.parse(val);
                const now = new Date();
                const day = now.getDay();
                if (day - oldDate.getDay() >= 7) {
                  handleChangeModal("open wallet page");
                  const datenow = new Date();
                  WebApp.CloudStorage.setItem("wallet_date", datenow.toString());

                }
              } else {
                handleChangeModal("open wallet page");
                const datenow = new Date();
                WebApp.CloudStorage.setItem("wallet_date", datenow.toString());

              }

            }
          });
        } else {

          // console.log("here")
          handleChangeModal("open wallet page");

        }


      }
    }, 5000)
    return () => clearTimeout(walletModalTime);
  }, { dependencies: [profile, isConnected, isMobile, walletShowOnce], scope: mainContainer })

  // useLayoutEffect(() => {
  //   if (isConnected) {
  //     if (WebApp.isVersionAtLeast("6.9")) {
  //       //Fetch cloud storage variable
  //       WebApp.CloudStorage.getItem("last_data", (e, val) => {
  //         if (e) {
  //           // console.log(e)
  //           // no previously saved data, ignore
  //         } else {
  //           if (val) {
  //             const olddata = JSON.parse(val);
  //             if (olddata && Object.hasOwn(olddata, 'taps')) lastdata.current.taps = olddata.taps;
  //             if (olddata && Object.hasOwn(olddata, 'points')) lastdata.current.points = olddata.points;
  //             if (olddata && Object.hasOwn(olddata, 'tTaps')) lastdata.current.tTaps = olddata.tTaps;
  //             if (olddata && Object.hasOwn(olddata, 'tPoints')) lastdata.current.tPoints = olddata.tPoints;
  //             if (lastdata.current.taps > 0 && lastdata.current.points > 0) {
  //               //If there are saved taps and points data from previous session
  //               let result = tapHandler(lastdata.current.taps, lastdata.current.points, false);
  //               result.then((res) => {
  //                 lastdata.current.taps = 0; // reset the taps count
  //                 lastdata.current.points = 0; // reset the points count
  //                 // update cloud storage
  //                 WebApp.CloudStorage.setItem("last_data", JSON.stringify(lastdata.current));

  //               }).catch((e) => {
  //                 //do nothing, keep the last value saved in cloud storage
  //               })
  //             }
  //             if (lastdata.current.tTaps > 0 && lastdata.current.tPoints > 0) {
  //               // If there were saved turbo taps and turbo points from last session
  //               let result = tapHandler(lastdata.current.tTaps, lastdata.current.tPoints, true);
  //               result.then((res) => {
  //                 lastdata.current.tTaps = 0; // reset the turbo taps
  //                 lastdata.current.tPoints = 0; // reset the turbo points
  //                 // update cloud storage
  //                 WebApp.CloudStorage.setItem("last_data", JSON.stringify(lastdata.current));

  //               }).catch((e) => {
  //                 //do nothing, keep the last value saved in cloud storage
  //               })
  //             }
  //           }
  //         }
  //       });
  //     }
  //   }
  // }, [isConnected])


  const [dotLottie, setDotLottie] = useState();
  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie);
  };

  useGSAP(() => {
    if (isConnected && isMobile) {
      loading_tl.current = gsap.timeline()
        .addLabel("start")
        .fromTo(".loading-div", { yPercent: 0 }, { yPercent: 100, duration: 1.5, ease: "power2.inOut" }, "start+=3.2")
        .fromTo(".loading-div-2", { yPercent: 0 }, { yPercent: 100, duration: 1.6, ease: "power2.inOut" }, "start+=3.2")
    }

  }, { dependencies: [isMobile, isConnected], scope: mainContainer, revertOnUpdate: true })


  useGSAP(() => {
    if (isMobile && scrambleModalTitle.current) {
      setTimeout(() => {
        scramble.current = new ScrambleText(scrambleModalTitle.current, {
          timeOffset: 30,
          chars: [
            'a', 'b', 'c', 'd', 'e', 'f', 'h', 'i', 'j', 'k', 'l',
            'n', 'p', 'r', 's', 't', 'u', 'v', 'X', 'y', 'z',
            '!', ':', ';', '@', '$', '?', '[', ']', '^', '{', '|', '}', '~'
          ]
        });
        scramble.current.start();
      }, 300);
    }
  }, { dependencies: [reanimateScramble, isMobile], scope: mainContainer, revertOnUpdate: true })

  const usersList = sampleUsers;
  const onlyAddOnce = useRef(true);
  const userList = useRef(usersList);


  useLayoutEffect(() => {
    if (name !== "User" && onlyAddOnce.current) {
      userList.current.push({
        username: name,
        level: cgpt.current.level,
        points: cgpt.current.value
      })
      onlyAddOnce.current = false;
    }
    userList.current.sort((a, b) => (a.points === b.points) ? ((a.username > b.username) ? 1 : -1) : (a.points < b.points) ? 1 : -1);
    // console.log(userList.current);
    // const tempUsers = sampleUsers.push({
    //     name: name,
    //     level: cgpt.level,
    //     points: cgpt.value
    // });
    // 
    // setUserList(tempUsers);
  }, [name])

  const renderDailyRewards = (daily) => {
    let dailyList = [];
    for (let i = 0; i < 30; i++) {
      dailyList.push(
        <div key={`day-${i}`} id={`day-${i}`} className='w-full relative flex flex-col items-center p-2'>
          <div className={`bg-modal-13 absolute top-0 left-0 size-full flex justify-center items-center z-[1] `}>
            <div className={`size-full relative bg-modal-23 ${daily[i] && daily[i].claimed == 1 ? 'bg-[#26262f]' : 'bg-[#1A1A1F]'} opacity-100`}></div>
          </div>
          <div className="relative flex flex-col gap-3 w-full z-[2] items-center">
            <h1 className="opacity-60 text-center text-[#efefe5]/60 text-[11px] font-normal font-['Violet_Sans'] uppercase leading-3 tracking-wide">Day {i + 1}</h1>
            <div className="aspect-square w-[24px] overflow-hidden rounded-full border-2 border-[#353539]">
              {daily[i] ? daily[i].claimed == 1 ? <div className="bg-friends-gradient size-full"></div> : <></> : <></>}
            </div>
            <h1 className="w-[62px] text-center text-[#efefe5] text-[11px] font-normal font-['Violet_Sans']">{(cgpt.current.jobLevel + 1) * (200 + 50 * i)}</h1>
          </div>
        </div>
      );
    }
    return dailyList;
  }




  return (
    <div ref={mainContainer} className='w-screen h-telegram overflow-hidden bg-colour relative'>

      {!isMobile ? <QRCode /> : (
        <div className='size-full relative'>
          {activeTab == "Home" && <HomePage name={name} cgpt={cgpt.current} handleValChange={handleValChange} handleButtonEvents={handleChangeModal} handleProfileView={handleProfileView} />}
          {activeTab == "Earn" && <EarnPage name={name} cgpt={cgpt.current} rerender={rerenderCards} handleValChange={handleValChange} handleButtonEvents={handleChangeModal} handleProfileView={handleProfileView} />}
          {activeTab == "Leaders" && <LeadersPage name={name} cgpt={cgpt.current} handleValChange={handleValChange} users={userList.current} handleProfileView={handleProfileView} />}
          {activeTab == "Friends" && <FriendsPage name={name} cgpt={cgpt.current} handleValChange={handleValChange} handleButtonEvents={handleChangeModal} handleProfileView={handleProfileView} />}
          {activeTab == "Quests" && <QuestsPage name={name} cgpt={cgpt.current} handleValChange={handleValChange} handleProfileView={handleProfileView} />}

          <div className="profile-page w-full h-full fixed top-0 left-0 z-[10]">
            {renderProfile && <ProfilePage name={name} handleProfileView={handleProfileView} />}
          </div>


          <div className="fixed bottom-0 left-0 w-full h-[5rem] z-[10] px-3 py-2 border-t border-[#353539] bg-colour flex-col  justify-start items-start flex">
            <div className="w-full h-full justify-start items-center gap-0.5 flex relative">

              <div id="home-nav-button" onClick={() => handleTabSwitching("Home")} className={`${activeTab == "Home" ? "active" : ""} tab-btn grow shrink basis-0 p-2.5 flex-col justify-start items-start gap-2.5 flex`}>
                <div className="w-full  flex-col justify-start items-center gap-2.5 flex">
                  <div className="w-[24px] h-[24px] relative">
                    <Icons name="Home" className="size-full relative" active={activeTab == "Home"} />
                  </div>
                  <div className="w-full text-center tab-text text-[11px] font-normal font-['Roboto Mono'] uppercase leading-[11px]">Home</div>
                </div>
              </div>
              <div id="earn-nav-button" onClick={() => handleTabSwitching("Earn")} className={`${activeTab == "Earn" ? "active" : ""} tab-btn grow shrink basis-0 p-2.5 flex-col justify-start items-start gap-2.5 flex`}>
                <div className="w-full  flex-col justify-start items-center gap-2.5 flex">
                  <div className="w-[24px] h-[24px] relative">
                    <Icons name="Wallet" className="size-full relative" active={activeTab == "Earn"} />
                  </div>
                  <div className="w-full text-center tab-text text-[11px] font-normal font-['Roboto Mono'] uppercase leading-[11px]">Earn</div>
                </div>
              </div>
              <div id="leaderboard-nav-button" onClick={() => handleTabSwitching("Leaders")} className={`${activeTab == "Leaders" ? "active" : ""} tab-btn grow shrink basis-0 p-2.5 flex-col justify-start items-start gap-2.5 flex`}>
                <div className="w-full  flex-col justify-start items-center gap-2.5 flex">
                  <div className="w-[24px] h-[24px] relative">
                    <Icons name="Bar Graph" className="size-full relative" active={activeTab == "Leaders"} />
                  </div>
                  <div className="w-full text-center tab-text text-[11px] font-normal font-['Roboto Mono'] uppercase leading-[11px]">Leaders</div>
                </div>
              </div>
              <div id="friends-nav-button" onClick={() => handleTabSwitching("Friends")} className={`${activeTab == "Friends" ? "active" : ""} tab-btn grow shrink basis-0 p-2.5 flex-col justify-start items-start gap-2.5 flex`}>
                <div className="w-full  flex-col justify-start items-center gap-2.5 flex">
                  <div className="w-[24px] h-[24px] relative">
                    <Icons name="User" className="size-full relative" active={activeTab == "Friends"} />
                  </div>
                  <div className="w-full text-center tab-text text-[11px] font-normal font-['Roboto Mono'] uppercase leading-[11px]">Friends</div>
                </div>
              </div>
              <div id="quests-nav-button" onClick={() => handleTabSwitching("Quests")} className={`${activeTab == "Quests" ? "active" : ""} tab-btn grow shrink basis-0 p-2.5 flex-col justify-start items-start gap-2.5 flex`}>
                <div className="w-full  flex-col justify-start items-center gap-2.5 flex">
                  <div className="w-[24px] h-[24px] relative">
                    <Icons name="Star" className="size-full relative" active={activeTab == "Quests"} />
                  </div>
                  <div className="w-full text-center tab-text text-[11px] font-normal font-['Roboto Mono'] uppercase leading-[11px]">Quests</div>
                </div>
              </div>
            </div>
          </div>



          <div className="modal-pointer modal-bg w-full fixed top-0 left-0 bg-[#09090E] bg-opacity-80 h-full z-[12] p-4 opacity-100">
            <div className="relative size-full modal flex justify-center items-center">
              <div className="relative w-full ">
                <div className="bg-modal-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                  <div className="size-full relative bg-modal-2 opacity-100"></div>
                </div>
                <div className=" relative w-full flex flex-col gap-6 justify-start items-center p-6 z-[2]">

                  <div className="text-box w-full grow flex flex-col items-start justify-center gap-1.5">
                    <div className="w-full flex-col justify-center items-start  flex">
                      <div className="px-2 py-1 bg-[#27ae60]/10 rounded-sm justify-start items-start inline-flex">
                        <div className="text-center text-[#27AE60] text-[13px] font-normal ">{modalType}</div>
                      </div>
                    </div>
                    <h1 ref={scrambleModalTitle} className=" text-[1.5rem] text-[#fff] font-['Violet_Sans']  tracking-[-0.5px]">
                      {modalTitle}
                    </h1>
                    <h1 className=" w-full font-light text-[#b2b2b2] text-xs">
                      {modalDescription}
                    </h1>
                    <div className="modal-card w-full aspect-[35/36] relative mt-2 flex justify-center">
                      <CardsModal level={modalCardLevel} className="size-full relative" />
                    </div>
                  </div>

                  <div className="modal-card-price w-full flex items-center justify-between gap-3 font-light text-[#b2b2b2] text-sm">
                    <div className=" text-white text-xs font-['Roboto_Mono'] uppercase font-light leading-[13.20px] tracking-[0.2em]">{modalQuantityText}</div>
                    <div className="text-white text-xl font-bold font-['Violet_Sans'] leading-[1]">{modalQuantity}</div>
                  </div>
                  <div className={`modal-boosts ${modalQuantity == "" ? "hidden" : "flex"} w-full px-3 border-light bg-[#1A1A1F] py-3 flex-col justify-center items-center gap-2.5 `}>
                    <div className="w-full text-white text-center text-xs font-['Roboto_Mono'] uppercase font-light leading-[13.20px] tracking-[0.2em]">{modalQuantityText}</div>
                    <div className="justify-center items-center gap-1 inline-flex">
                      <div className="text-white text-2xl font-bold font-['Violet_Sans'] leading-[1]">{modalQuantity}</div>
                    </div>
                  </div>


                  <button onClick={(e) => handleButtonEvents("main button", e)} className={`buttons ${modalTitle && modalType ? modalType.slice(0).replace(/\s+/g, '-').toLowerCase() + '-' + modalTitle.slice(0).replace(/\s+/g, '-').toLowerCase() : ''} relative w-full`}>

                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-modal-cgpt-short relative size-full "></div></div>
                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-modal-cgpt-2 relative size-full "></div></div>
                    <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <filter id="flt_tag">
                          <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                          <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                        </filter>
                        <filter id="flt_tag_2">
                          <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                          <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                        </filter>
                      </defs>
                    </svg>
                    <div className="w-full relative py-4 px-6 flex justify-center items-center gap-2">
                      <p className="text-white text-sm font-['Roboto_Mono']">{buttonLabel}</p>
                    </div>
                  </button>
                  <div onClick={(e) => handleButtonEvents("close", e)} className="close-button absolute pointer-events-auto right-[1.5rem] top-[1.5rem] w-[1.5rem] aspect-square ">
                    <CloseButton />
                  </div>

                </div>

              </div>
            </div>

          </div>


          <div className="boost-page-container pointer-events-none modal-bg w-full fixed top-0 left-0 bg-[#09090E] bg-opacity-80 h-full z-[12] p-4 opacity-100">
            <div className="relative size-full boost-page flex justify-center items-center">
              <div className="relative w-full ">
                <div className="bg-modal-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                  <div className="size-full relative bg-modal-2 opacity-100"></div>
                </div>
                <div className=" relative w-full flex flex-col gap-6 justify-start items-center p-6 z-[2]">

                  <div className="text-box w-full grow flex flex-col items-start justify-center gap-1.5">
                    <div className="w-full flex-col justify-center items-start  flex">
                      <div className="px-2 py-1 bg-[#27ae60]/10 rounded-sm justify-start items-start inline-flex">
                        <div className="text-center text-[#27AE60] text-[13px] font-normal ">Boosts</div>
                      </div>
                    </div>
                    <h1 className=" text-[1.5rem] text-[#fff] font-['Violet_Sans']  tracking-[-0.5px]">How Boosts Work</h1>
                    <h1 className=" w-full font-light text-[#b2b2b2] text-xs">There are four different kind of boosts:</h1>

                  </div>


                  <div className="w-full h-auto relative overflow-scroll">
                    <div className="relative w-full grid grid-cols-2 gap-2" >
                      <div className='w-full relative flex flex-col items-center px-4 py-6 justify-start'>
                        <div className={`bg-modal-13 absolute top-0 left-0 size-full flex justify-center items-center z-[1] `}>
                          <div className={`size-full relative bg-modal-23 bg-[#1A1A1F] opacity-100`}></div>
                        </div>
                        <div className="relative flex flex-col gap-3 w-full z-[2] items-center">
                          <div className="w-[3rem] aspect-square relative">
                            <Icons name="Full Battery" className="size-full" />
                          </div>
                          <h1 className="text-center text-white text-[14px] font-normal font-['Violet_Sans']">Full Energy</h1>
                          <h1 className="opacity-80 text-center text-[#efefe5] leading-[1] text-[11px] font-light font-['Violet_Sans']">{`Instantly restores 100% of energy`}</h1>


                        </div>
                      </div>
                      <div className='w-full relative flex flex-col items-center px-4 py-6 justify-start'>
                        <div className={`bg-modal-13 absolute top-0 left-0 size-full flex justify-center items-center z-[1] `}>
                          <div className={`size-full relative bg-modal-23 bg-[#1A1A1F] opacity-100`}></div>
                        </div>
                        <div className="relative flex flex-col gap-3 w-full z-[2] items-center">
                          <div className="w-[3rem] aspect-square relative">
                            <Icons name="Lightning Outline" className="size-full" />
                          </div>
                          <h1 className="text-center text-white text-[14px] font-normal font-['Violet_Sans']">Turbo</h1>
                          <h1 className="opacity-80 text-center text-[#efefe5] leading-[1] text-[11px] font-light font-['Violet_Sans']">{`Each tap multiplies by 5 for 20 seconds`}</h1>

                        </div>
                      </div>
                      <div className='w-full relative flex flex-col items-center px-4 py-6 justify-start'>
                        <div className={`bg-modal-13 absolute top-0 left-0 size-full flex justify-center items-center z-[1] `}>
                          <div className={`size-full relative bg-modal-23 bg-[#1A1A1F] opacity-100`}></div>
                        </div>
                        <div className="relative flex flex-col gap-3 w-full z-[2] items-center">
                          <div className="w-[3rem] aspect-square relative">
                            <Icons name="Multitap" className="size-full" />
                          </div>
                          <h1 className="text-center text-white text-[14px] font-normal font-['Violet_Sans']">Multitap</h1>
                          <h1 className="opacity-80 text-center text-[#efefe5] leading-[1] text-[11px] font-light font-['Violet_Sans']">{`Increases amount of coins you can earn per one tap. +1 per level`}</h1>

                        </div>
                      </div>
                      <div className='w-full relative flex flex-col items-center px-4 py-6 justify-start'>
                        <div className={`bg-modal-13 absolute top-0 left-0 size-full flex justify-center items-center z-[1] `}>
                          <div className={`size-full relative bg-modal-23 bg-[#1A1A1F] opacity-100`}></div>
                        </div>
                        <div className="relative flex flex-col gap-3 w-full z-[2] items-center">
                          <div className="w-[3rem] aspect-square relative">
                            <Icons name="Lightning Battery" className="size-full" />
                          </div>
                          <h1 className="text-center text-white text-[14px] font-normal font-['Violet_Sans']">Energy Limit</h1>
                          <h1 className="opacity-80 text-center text-[#efefe5] leading-[1] text-[11px] font-light font-['Violet_Sans']">{`Increases limit of energy. +500 per level`}</h1>

                        </div>
                      </div>
                    </div>

                  </div>

                  <button onClick={(e) => handleChangeModal("close boost page", e)} className="pointer-events-auto relative w-full">

                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-modal-cgpt-short relative size-full "></div></div>
                    <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-modal-cgpt-2 relative size-full "></div></div>
                    <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <filter id="flt_tag">
                          <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                          <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                        </filter>
                        <filter id="flt_tag_2">
                          <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                          <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                        </filter>
                      </defs>
                    </svg>
                    <div className="w-full relative py-4 px-6 flex justify-center items-center gap-2">
                      <p className="text-white text-sm font-['Roboto_Mono']">Got it!</p>
                    </div>
                  </button>
                  <div onClick={(e) => handleChangeModal("close boost page", e)} className=" absolute pointer-events-auto right-[1.5rem] top-[1.5rem] w-[1.5rem] aspect-square ">
                    <CloseButton />
                  </div>

                </div>

              </div>
            </div>

          </div>

          <div className="daily-modal-container w-full  fixed top-0 left-0 h-full bg-[#09090E] z-[12] pointer-events-none">
            <div className="daily-modal relative w-full h-full ">
              <div onClick={(e) => { handleChangeModal("close daily page", e) }} className="daily-bg absolute size-full pointer-events-auto "></div>
              <div className="daily absolute pointer-events-auto w-full h-[95%] bottom-0 left-0 bg-[#171718] rounded-t-3xl flex flex-col gap-4 justify-between items-center p-6">
                <div onTouchMove={(e) => { handleChangeModal("close daily page", e) }} className="absolute top-[0.5rem] left-0 w-full flex justify-center h-[8rem] z-[4]">
                  <div className="w-1/3 h-[4px] rounded-full bg-[#2f2f39]"></div>
                </div>
                <div className="flex flex-col gap-2 w-full items-center">
                  <div className="w-full h-[8rem] relative">
                    <img src={dailyRewardImage} alt="" className='size-full object-contain object-center' />
                  </div>
                  <h1 className=" text-[1.7rem] text-center  text-[#fff] font-['Violet_Sans']  tracking-[-0.5px]">Daily Rewards</h1>
                  <h1 className="w-3/4 font-light text-center text-[#b2b2b2] text-xs">{`Collect your daily rewards by clicking on the button below`}</h1>
                </div>
                <div className="w-full h-full relative overflow-scroll" id="daily-container">
                  <div className="absolute top-0 left-0 w-full grid grid-cols-4 gap-2" >
                    {renderDailyRewards(dailyRewards)}
                  </div>

                </div>

                <button id="daily-rewards-button" onClick={(e) => handleButtonEvents("main button", e)} className={`${dailyRewards.length > 0 && dailyRewards[dailyRewards.length - 1].claimed == 0 ? 'pointer-events-auto opacity-100' : 'opacity-40 pointer-events-none'} relative w-full z-[4]`}>
                  <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-modal-cgpt-short relative size-full "></div></div>
                  <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-modal-cgpt-2 relative size-full "></div></div>
                  <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <filter id="flt_tag">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                        <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                      </filter>
                      <filter id="flt_tag_2">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                        <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                      </filter>
                    </defs>
                  </svg>
                  <div className="w-full relative py-4 px-6 flex justify-center items-center gap-2">
                    <p className="text-white text-sm font-['Roboto_Mono']">{dailyRewards.length > 0 && dailyRewards[dailyRewards.length - 1].claimed == 0 ? 'Collect' : 'Collected'}</p>
                  </div>
                </button>
              </div>

            </div>
          </div>

          <div className="wallet-modal-container w-full  fixed top-0 left-0 h-full bg-[#09090E] z-[12] pointer-events-none">
            <div className="wallet-modal relative w-full h-full ">
              <div onClick={(e) => { handleChangeModal("close wallet page", e) }} className="wallet-bg absolute size-full pointer-events-auto "></div>
              <div className="wallet absolute pointer-events-auto w-full h-[95%] bottom-0 left-0 bg-[#171718] rounded-t-3xl flex flex-col gap-4 justify-between items-center p-6">
                <div onTouchMove={(e) => { handleChangeModal("close wallet page", e) }} className="absolute top-[0.5rem] left-0 w-full flex justify-center h-[8rem] z-[4]">
                  <div className="w-1/3 h-[4px] rounded-full bg-[#2f2f39]"></div>
                </div>
                <div className="absolute top-0 right-0 w-full flex justify-end py-8 px-6 z-[5]">
                  <h1 onClick={(e) => { handleChangeModal("close wallet page", e) }} className="pointer-events-auto text-sm text-center  text-[#efefe5] font-['Roboto_Mono'] uppercase tracking-[-0.5px]">Skip</h1>
                </div>
                <div className="flex flex-col gap-2 w-full items-center">
                  <div className="w-full h-[8rem] relative">
                    <img src={walletImage} alt="" className='size-full object-contain object-center' />
                  </div>
                  <h1 className=" text-[1.7rem] text-center  text-[#fff] font-['Violet_Sans']  tracking-[-0.5px]">Add an EVM Wallet</h1>
                  <h1 className="w-3/4 font-light text-center text-[#b2b2b2] text-xs">{`Tell us what's your EVM wallet on BNB Chain for your end-of season airdrop & rewards`}</h1>
                </div>
                <div className="w-full h-full relative" id="wallet-container">
                  <div className="relative w-full flex flex-col gap-1 justify-start items-start">
                    <div className="absolute w-full h-full pointer-events-none p-2.5">
                      <img src={BNBLogo} alt="" className=' h-full aspect-square object-contain object-center' />
                    </div>
                    <input ref={walletAddress} type="text" id="wallet-address" name="wallet-address" className="bg-[#121217] border border-[#353539] text-[#969692] focus:text-white active:text-white text-sm font-['Roboto_Mono'] rounded-xl active:ring-0 active:border-[#353539] outline-none focus:ring-0 focus:border-[#353539] block w-full py-2.5 pr-2.5 pl-[48px]" />

                  </div>
                  <p ref={warningMessage} className="warning-message mt-2 text-sm text-red-500 text-[12px] leading-1 "></p>
                </div>

                <button id="wallet-button" onClick={(e) => handleButtonEvents("main button", e)} className={` relative w-full z-[4] pointer-events-auto`}>
                  <div className="absolute top-0 left-0 size-full btn-cgpt-rounded"><div className="btn-modal-cgpt-short relative size-full "></div></div>
                  <div className="absolute top-0 left-0 size-full btn-cgpt-rounded-2"><div className="btn-modal-cgpt-2 relative size-full "></div></div>
                  <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <filter id="flt_tag">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                        <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                      </filter>
                      <filter id="flt_tag_2">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="1.5" result="blur" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                        <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                      </filter>
                    </defs>
                  </svg>
                  <div className="w-full relative py-4 px-6 flex justify-center items-center gap-2">
                    <p className="text-white text-sm font-['Roboto_Mono']">{`Save Wallet`}</p>
                  </div>
                </button>
              </div>

            </div>
          </div>

          <div className="loading-div-2 absolute top-0 left-0 size-full gradient-l-to-r z-[14] flex justify-end"></div>
          <section className="loading-div px-6 w-full bg-colour h-telegram z-[15] pointer-events-auto fixed top-0 left-0 flex flex-col justify-start items-center overflow-hidden">
            <div className="w-full basis-1/5 flex justify-center items-end relative py-2">
              <img src={LogoImage} className="w-full h-[3.5rem] relative object-contain object-center" />
            </div>
            <div className="w-full basis-3/5 flex justify-center ">
              <img src={LoadingImage} className="w-full h-full max-w-[300px] object-contain object-[50%_80%]" />
            </div>
            <div className='w-full basis-1/5 relative max-w-[300px]'>
              <div className="absolute top-0 left-0 size-full loading-lottie opacity-100 flex flex-col justify-start items-start px-12">
                <div className="w-full h-full relative z-[3] px-3">
                  <Player
                    className="size-full"
                    src="/loader_tap.json"
                    autoplay
                    loop
                    lottieRef={dotLottieRefCallback}
                  />
                </div>
              </div>
            </div>

          </section>

        </div>
      )
      }

    </div>
  )
}

export default App
