import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { createContext } from "react";
import WebApp from '@twa-dev/sdk'
import { ENERGY_LEVEL_MULTIPLIER, jobsVal } from "../constants";
import axios from "axios";
import { randomInteger } from "../scripts/utils";

const wsURL = import.meta.env.VITE_WEBSOCKET_SERVER_URL;
WebApp.ready();
const defaultValues = {
    userId: "",
    profile: {
        name: null,
        email: null,
        username: null,
        wallet: "0"
    },
    points: 0,
    level: 0,
    taps: 0,
    energyLevel: 0,
    energyLimit: 0,
    turbo: 0,
    fullEnergy: 0,
    isConnected: false,
    isMobile: false,
    isAllowed: false,
    multitapLevel: 1,
    energyLimitLevel: 1,
    profit: 0,
    // rechargeSpeedLevel: 1,
    tasks: [],
    cards: [],
    refs: [],
    leaderboards: [],
    dailyRewards: [{
        claimed: 0,
        date: "2024-08-05",
        reward: 0,
        day: 0
    }],
    inviteLink: "",
    activeCard: jobsVal[0],
    tapHandler: async() => { },
    taskHandler: () => { },
    boostHandler: () => { },
    cardHandler: () => { },
    refHandler: () => { },
    leaderHandler: () => { },
    rewardsHandler: () => { },
    webHandler: () => { },
    profileHandler: () => { }
}

export const getUserIdFromInitData = (telegramInitData) => {
    if (telegramInitData) {
        const initData = new URLSearchParams(telegramInitData)
        return JSON.parse(initData.get("user")).id
    } else {
        return null;
    }
}

export const UserContext = createContext(defaultValues)


export const UserContextProvider = ({ children }) => {
    const [profile, setProfile] = useState({
        name: null,
        email: null,
        username: null,
        wallet: "0"
    });
    // const [auth, setAuth] = useState("");
    const apiInstance = useRef();
    const [loading, setLoading] = useState(false)
    const [isConnected, setIsConnected] = useState(false);
    const [isAllowed, setIsAllowed] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [userId, setUserId] = useState("");
    const [points, setPoints] = useState(0);
    const [energyLevel, setEnergyLevel] = useState(0);
    const [level, setLevel] = useState(0);
    const [taps, setTaps] = useState(0);
    const [turbo, setTurbo] = useState(0);
    const [activateTurbo, setActivateTurbo] = useState(false);
    const [fullEnergy, setFullEnergy] = useState(0);
    const [multitapLevel, setMultitapLevel] = useState(1);
    const [energyLimitLevel, setEnergyLimitLevel] = useState(1);
    const [profit, setProfit] = useState(0);
    // const [rechargeSpeedLevel, setRechargeSpeedLevel] = useState(1);
    const [energyLimit, setEnergyLimit] = useState(0);
    const [cards, setCards] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [refs, setRefs] = useState([]);
    const [leaderboards, setLeaderboards] = useState([]);
    const [dailyRewards, setDailyRewards] = useState([
        {
            claimed: 0,
            date: "2024-08-05",
            reward: 0,
            day: 0
        }
    ]);
    const [inviteLink, setInviteLink] = useState("");
    const [activeCard, setActiveCard] = useState(
        jobsVal[0]
    );
    const [ws, setWs] = useState(0)


    // const initData = WebApp.initData ? WebApp.initData : null;
    const initData = WebApp.initData ? WebApp.initData : import.meta.env.VITE_NETLIFY_ALLOW && import.meta.env.VITE_NETLIFY_ALLOW === "true" ? import.meta.env.VITE_INITDATA : null;


    useEffect(() => {
        let mobile;
        if (WebApp.platform) {
            if (WebApp.platform === "ios" || WebApp.platform === "android") {
                mobile = true;
            } else {
                mobile = import.meta.env.VITE_NETLIFY_ALLOW && import.meta.env.VITE_NETLIFY_ALLOW === "true"; //Change
            }
        } else {
            mobile = false;
        }
        setIsMobile(mobile);
        
        let ws_temp;
        let authenticateDelay;
        if (initData) {
            authenticateDelay = setTimeout(() => {
                axios.post(`${import.meta.env.VITE_API_BASE_URL}/authenticate`, { initData: initData })
                    .then((res) => {

                        sessionStorage.setItem('acct', res.data.accessToken);

                        if(localStorage.getItem('localTaps') > 0 && localStorage.getItem('localPoints')){
                            tapHandler(localStorage.getItem('localTaps'), localStorage.getItem('localPoints'), false);
                            localStorage.setItem('localTaps', 0);
                            localStorage.setItem('localPoints', 0);
                        }

                        // console.log(res)

                        if (mobile) {
                            connectWs(ws_temp);
                        }

                    }).catch((error) => {
                        // handle error
                        // console.log(error.response)
                    })
            }, randomInteger(1, 400))

        }

        return () => {
            if (ws) { ws.close() }
            if (authenticateDelay) { clearTimeout(authenticateDelay) }
        }
    }, [initData]);


    const connectWs = (wsocket) => {
        console.log("connecting");
        wsocket = new WebSocket(wsURL, [sessionStorage.getItem('acct')]);
        wsocket.onmessage = handleWsMsg
        wsocket.onclose = (e) => {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(() => {
                connectWs(wsocket);
            }, randomInteger(300, 1000));
        }
        wsocket.onopen = () => {
            console.log("Connection opened")
            // wsocket.send("subscribe")
            setWs(wsocket)
        }
    }

    const closeWs = () => {
        if (ws) ws.close();
    }


    const tapHandler = async(taps, points, isTurboMode = false) => {
        // console.log("tap handler is being called")
        return await axios.post(`${import.meta.env.VITE_API_BASE_URL}/tap`,{
            points: points,
            taps: taps,
            isTurboMode: isTurboMode
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('acct')}`
            }
        })
    }

    const boostHandler = (boostType, access) => {

        switch (boostType) {
            case "Fetch":
                axios.get(`${import.meta.env.VITE_API_BASE_URL}/boosts`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        // console.log(res.data)
                        setEnergyLimitLevel(res.data.energyLimitLevel);
                        setMultitapLevel(res.data.multiTapLevel);
                        setTurbo(res.data.tappingGuru);
                        setActivateTurbo(true);
                        setFullEnergy(res.data.fullEnergy);

                    })
                        
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                break;
            case "Turbo":
                if (ws) {
                    ws.send("Tapping Guru")
                }
                break;
            case "Full Energy":
                if (ws) {
                    ws.send("Full Energy")
                }
                break;
            case "Multitap":
                if (ws) {
                    ws.send("upgradeMultiTap")
                }
                break;
            case "Energy Limit":
                if (ws) {
                    ws.send("upgradeEnergyLimit")
                }
                break;
            case "Recharge Speed":
                if (ws) {
                    ws.send("upgradeRechargeSpeed")
                }
                break;
        }


    }

    const rewardsHandler = (actionType) => {
        switch (actionType) {
            case "Profit":
                axios.get(`${import.meta.env.VITE_API_BASE_URL}/earnings`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        // console.log("Profit", res.data);
                        if (res.data !== '' && res.data.constructor === Object) {
                            setProfit(res.data.points);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                break;
            case "Daily Rewards":
                axios.get(`${import.meta.env.VITE_API_BASE_URL}/dailyRewards`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        // console.log("Daily Rewards", res.data);
                        if (res.data !== '' && res.data.length > 0) {
                            setDailyRewards(res.data);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                break;

            case "Claim Daily Rewards":
                axios.post(`${import.meta.env.VITE_API_BASE_URL}/collectDailyRewards`, {}, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        // console.log("Claim Daily Rewards", res.data);
                        rewardsHandler("Daily Rewards");
                        leaderHandler();
                        cardHandler("Fetch");
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                break;
        }
    }

    const leaderHandler = () => {
        if (isConnected && isMobile) {
            axios.get(`${import.meta.env.VITE_API_BASE_URL}/leaderboard`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                }
            })
                .then(res => {
                    // console.log(res.data);
                    if (res.data !== '' && res.data.length > 0) {
                        let leaderboardData = res.data;
                        leaderboardData.sort((a, b) => (a.rank > b.rank) ? 1 : ((b.rank > a.rank) ? -1 : 0))
                        // console.log(leaderboardData);
                        setLeaderboards(leaderboardData);
                    }
                })
                .catch(function (error) {
                    // handle error
                    // console.log(error);
                })
        }
    }


    const taskHandler = (actionType, user_id, task_id = "") => {
        switch (actionType) {
            case "Fetch":
                axios.get(`${import.meta.env.VITE_API_BASE_URL}/tasks/all`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        // console.log(res.data);
                        if (res.data !== '' && res.data.constructor === Object) {
                            if (res.data.tasks) {
                                // console.log(res.data.tasks);
                                setTasks(res.data.tasks);
                            }
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                break;
            case "Claim":
                axios.post(`${import.meta.env.VITE_API_BASE_URL}/task/claim/${task_id}`, {}, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        // console.log("Claiming Task Rewards ", res.data);
                        if (res.data) {
                            taskHandler("Fetch", user_id);
                            setPoints(res.data);
                            cardHandler("Fetch", user_id);
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                break;
            case "Click":
                axios.get(`${import.meta.env.VITE_API_BASE_URL}/tasks/click/${task_id}`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        console.log("clicking Task" + task_id);
                    })
                    .catch(function (error) {
                        // handle error
                        // console.log(error);
                    })
                break;

        }

    }

    const refHandler = () => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/refs`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('acct')}`
            }
        })
            .then(res => {
                // console.log(res.data);
                if (res.data !== '' && res.data.constructor === Object) {
                    if (res.data.refs) {
                        setRefs(res.data.refs);
                        setInviteLink(res.data.inviteLink);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }


    const cardHandler = (actionType, card_id = "") => {
        // const initData = {user : {id : 123451}}
        switch (actionType) {
            case "Fetch":
                axios.get(`${import.meta.env.VITE_API_BASE_URL}/cards`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        // console.log(res.data);
                        if (res.data !== '' && res.data.length > 0) {
                            let sortedCards = res.data;
                            sortedCards.sort((a, b) => (a.requiredlevel > b.requiredlevel) ? 1 : ((b.requiredlevel > a.requiredlevel) ? -1 : 0))
                            const active_card = sortedCards.findIndex(item => item.status === "active") >= 0 ? sortedCards.findIndex(item => item.status === "active") : 0;
                            setCards(sortedCards);
                            setActiveCard(sortedCards[active_card]);
                            // console.log(res.data[active_card]);
                        }
                        setLoading(false);
                    })
                    .catch(function (error) {
                        setLoading(false);
                        // handle error
                        // console.log(error);
                    })
                break;
            case "Buy":
                axios.post(`${import.meta.env.VITE_API_BASE_URL}/cards/buy/${card_id}`, {}, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        // console.log("Buying Card" + card_id);
                        cardHandler("Fetch");
                    })
                    .catch(function (error) {
                        // handle error
                        // console.log(error);
                    })
                break;
            case "Activate":
                if(!loading){
                    setLoading(true);
                    axios.post(`${import.meta.env.VITE_API_BASE_URL}/cards/activate/${card_id}`, {}, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                        }
                    })
                        .then(res => {
                            // console.log("Selecting Card" + card_id);
                            cardHandler("Fetch");
                        })
                        .catch(function (error) {
                            setLoading(false);
                            // handle error
                            // console.log(error);
                        })
                }
                break;

        }
    }


    const profileHandler = (actionType, data = {}) => {
        switch (actionType) {
            case "Fetch":
                axios.get(`${import.meta.env.VITE_API_BASE_URL}/info`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })
                    .then(res => {
                        if (res.data !== '' && res.data.constructor == Object) {
                            setProfile(res.data);
                            // console.log(res.data)
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        // console.log(error);
                    })
                break;

            case "Change Profile":
                return axios.post(`${import.meta.env.VITE_API_BASE_URL}/info`, data, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('acct')}`
                    }
                })

        }
    };


    const startTime = useRef(performance.now());
    const endTime = useRef(performance.now());

    const start = () => {
        startTime.current = performance.now();
    };

    const end = () => {
        endTime.current = performance.now();
        var timeDiff = endTime.current - startTime.current; //in ms 
        // strip the ms 
        // console.log(timeDiff + " milliseconds");
    }

    const timerstart = useRef(true);

    const handleWsMsg = (msg) => {
        const parsedMsg = JSON.parse(msg.data)
        // console.log(parsedMsg);
        if (parsedMsg['type'] == "update" && parsedMsg["field"] == 'points') {
            setPoints(parsedMsg['points'])
            end();
        }

        else if (parsedMsg["status"] == "open") {
            // User current energy value
            setIsConnected(true);
            const parsedInitialData = parsedMsg['data'];
            parsedInitialData.map((val, idx) => {
                switch (val.property) {
                    case "energy":
                        // console.log("energy", val.value);
                        setEnergyLevel(val.value);
                        break;

                    case "level":
                        // console.log("level", val.value);
                        setLevel(val.value)
                        break;

                    case "points":
                        // console.log("points", val.value);
                        setPoints(val.value)
                        break;

                    case "taps":
                        // console.log("taps", val.value);
                        setTaps(val.value)
                        break;

                    case "energyLimit":
                        // console.log("energyLimit", val.value); 
                        setEnergyLimit(val.value)
                        break;
                   
                }
            })

        }
        else if (parsedMsg["type"] == "update" && parsedMsg['field'] == "energy") {
            // User current energy value
            setEnergyLevel(parsedMsg['energy'])
        } else if (parsedMsg["type"] == "update" && parsedMsg['field'] == "level") {
            // User current energy value
            setLevel(parsedMsg['level'])
            // console.log(parsedMsg['level']);
        } else if (parsedMsg["type"] == "update" && parsedMsg['field'] == "taps") {
            // User current energy value
            setTaps(parsedMsg['taps'])
            // console.log(parsedMsg['taps']);
        }
        else if (parsedMsg['type'] == "update" && parsedMsg['field'] == "energyLimit") {
            // User Maximum energy value 
            setEnergyLimit(parsedMsg['energyLimit'])
        }
        else if (parsedMsg['type'] == "boost" && parsedMsg['field'] == "used" && parsedMsg['name'] == "Tapping Guru") {
            // 
            setActivateTurbo(true);
            setTurbo(parsedMsg['used'])
            // console.log("super speed remaining: ", parsedMsg['used'])
        }
        else if (parsedMsg['type'] == "boost" && parsedMsg['field'] == "used" && parsedMsg['name'] == "Full Energy") {
            // 
            setFullEnergy(parsedMsg['used'])
            // console.log("full energy remaining: ", parsedMsg['used'])
        }
        else if (parsedMsg['type'] == "update" && parsedMsg['field'] == "multi_tap_level") {
            // 
            setMultitapLevel(parsedMsg['multi_tap_level'])
        }
        else if (parsedMsg['type'] == "update" && parsedMsg['field'] == "energy_limit_level") {
            // 
            setEnergyLimitLevel(parsedMsg['energy_limit_level'] / ENERGY_LEVEL_MULTIPLIER)
        } 
        if (parsedMsg['type'] == "update" && parsedMsg["field"] == 'isBanned') {
            window.location.reload();
        }
        // else if (parsedMsg['type'] == "card" && parsedMsg['field'] == "earning") {
        //     // 
        //     setProfit(parsedMsg['earnings'])
        //     console.log(parsedMsg['earnings']);
        // }


    }



    useEffect(() => {
        if (isConnected && isMobile) {
            const user = getUserIdFromInitData(initData);
            if (user) {
                setUserId(user);
            }
            profileHandler("Fetch");
            cardHandler("Fetch");
            boostHandler("Fetch");
            taskHandler("Fetch");
            refHandler();
            leaderHandler();
            rewardsHandler("Profit");
            rewardsHandler("Daily Rewards");
        }
    }, [isConnected, isMobile]);





    return <UserContext.Provider value={{ profile, loading, isConnected, isAllowed, taps, level, isMobile, userId, points, energyLevel, cards, activeCard, energyLimit, energyLimitLevel, multitapLevel, turbo, activateTurbo, fullEnergy, tasks, refs, inviteLink, profit, dailyRewards, leaderboards, tapHandler, boostHandler, cardHandler, taskHandler, rewardsHandler, refHandler, leaderHandler, profileHandler }}>
        {children}
    </UserContext.Provider>
}