import React, { useContext, useEffect, useRef } from 'react';

import Icons from '../components/Icons';
import Avatar from '../assets/Avatar.webp'

import Profile1 from '../assets/logo-symbol.webp'

import { useGSAP } from '@gsap/react';
import gsap from 'gsap/all';
import Cards from '../components/Cards';
import { jobsVal, levelsVal } from '../constants';
import { UserContext } from '../contexts/userContext';

const EarnPage = ({ name, cgpt, handleValChange, handleButtonEvents, rerender, handleProfileView }) => {
    const { cards, cardHandler, userId, isConnected, isMobile } = useContext(UserContext);
    const mainContainer = useRef();
    const scrollCards = useRef();
    const lastTab = useRef("my cards");
    const { contextSafe } = useGSAP({ dependencies: [rerender], scope: mainContainer, revertOnUpdate: true });
    const handlePointerEvents = contextSafe((type, object) => {
        object.preventDefault();
        if (type == "my cards") {
            if (lastTab.current == "new cards") {
                scrollCards.current.scrollTop = 0;
                gsap.fromTo(".bg-tab-cards", { xPercent: 100 }, { xPercent: 0, duration: 0.5, ease: "power2.inOut" })
                gsap.fromTo(".new-cards", { scale: 1, autoAlpha: 1 }, { scale: 0, autoAlpha: 0, duration: 0.3, stagger: 0.1, onComplete: () => { gsap.to(".new-cards-div", { autoAlpha: 0, duration: 0.02 }) } })
                gsap.to(".my-cards-div", { autoAlpha: 1, duration: 0.3, onComplete: () => { gsap.fromTo(".my-cards", { scale: 0, autoAlpha: 0 }, { scale: 1, autoAlpha: 1, duration: 0.3, stagger: 0.1 }) } })
                lastTab.current = "my cards";
            }

        } else if (type == "new cards") {
            if (lastTab.current == "my cards") {
                scrollCards.current.scrollTop = 0;
                gsap.fromTo(".bg-tab-cards", { xPercent: 0 }, { xPercent: 100, duration: 0.5, ease: "power2.inOut" })
                gsap.fromTo(".my-cards", { scale: 1, autoAlpha: 1 }, { scale: 0, autoAlpha: 0, duration: 0.3, stagger: 0.1, onComplete: () => { gsap.to(".my-cards-div", { autoAlpha: 0, duration: 0.02 }) } })
                gsap.to(".new-cards-div", { autoAlpha: 1, duration: 0.3, onComplete: () => { gsap.fromTo(".new-cards", { scale: 0, autoAlpha: 0 }, { scale: 1, autoAlpha: 1, duration: 0.3, stagger: 0.1 }) } })
                lastTab.current = "new cards";
            }

        }
    })

    useEffect(() => {
        if (isConnected && isMobile) cardHandler("Fetch");
    }, [isConnected, isMobile])

    useGSAP(() => {
        gsap.to(".new-cards-div", { autoAlpha: 0, duration: 0.02 })
        gsap.fromTo(".new-cards", { scale: 1, autoAlpha: 1 }, { scale: 0, autoAlpha: 0, duration: 0.02 });
        lastTab.current = "my cards";
        scrollCards.current.scrollTop = 0;
    }, { dependencies: [rerender], scope: mainContainer, revertOnUpdate: true })

    useGSAP(() => {
        // console.log(point)
        const intervalId = setInterval(() => {
            gsap.to(".coins-val", { text: cgpt.value, duration: 0.02 });
            gsap.to(".coinspertap", { text: cgpt.multitapLevel * cgpt.multiplier, duration: 0.02 });
            gsap.to(".coinstolevelup", { text: cgpt.coinstolevelup, duration: 0.02 });
            gsap.to(".profit", { text: cgpt.jobProfit, duration: 0.02 });

        }, 100)
        return () => clearInterval(intervalId);
    }, { scope: mainContainer, revertOnUpdate: true })
    return (
        <div ref={mainContainer} className={` ${rerender ? "rerendered" : "rerender-div"} w-full h-dvh flex flex-col gap-4 px-4 py-4 relative z-[1]`}>

            <div className="w-full h-[2rem] flex justify-between z-[8]">
                <div className="h-full aspect-square">
                    <Icons name="Logo" className="size-full" />
                </div>
                <div onClick={(e) => handleProfileView("open profile page", e)} className='flex h-full items-center justify-end gap-2'>
                    <div className="h-full aspect-square">
                        <Icons name="Photo Frame" className="size-full" imagesrc={Profile1} />
                    </div>
                    <h1 className="text-white font-['Roboto_Mono'] uppercase text-[12px]">{name}</h1>
                </div>
            </div>
            <div className="w-full flex-col justify-start items-start gap-2 inline-flex relative blur-bg z-[8]">
                <div className="bg-card-1 absolute top-0 left-0 size-full flex justify-center items-center z-[1] ">
                    <div className="size-full relative bg-card-2 opacity-50"></div>
                </div>
                <div className="w-full  flex-col justify-start items-start flex px-3 py-3 relative z-[2]">
                    <div className="w-full flex-col justify-start items-start gap-2 flex font-['Violet_Sans']">
                        <div className="w-full flex-col justify-center items-start gap-2.5 flex">
                            <div className="px-2 py-1 bg-[#f8cf3f]/10 rounded-sm justify-start items-start gap-2.5 inline-flex">
                                <div className="text-center text-[#f8cf3f] text-[13px] font-normal ">Total Balance</div>
                            </div>
                        </div>
                        <div className="w-full text-[#efefe5] text-[30px] font-normal  leading-9"><span className='coins-val'>{cgpt.value}</span></div>
                        <div className="w-full flex-col justify-start items-start gap-2 flex">
                            <div className="w-full justify-between items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Earn per tap</div>
                                <div className="justify-start items-center gap-2.5 flex">
                                    <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='coinspertap'>{cgpt.multitapLevel * cgpt.multiplier}</span></div>
                                </div>
                            </div>
                            <div className="w-full justify-between items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Coins to level up</div>
                                <div className="justify-start items-center gap-2.5 flex">
                                    <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='coinstolevelup'>{cgpt.coinstolevelup}</span></div>
                                </div>
                            </div>
                            <div className="w-full justify-between items-center gap-4 inline-flex">
                                <div className="grow shrink basis-0 text-white text-sm font-normal  leading-tight">Profit</div>
                                <div className="justify-start items-center gap-2.5 flex">
                                    <div className="text-right text-[#efefe5]/60 text-sm font-normal  leading-tight"><span className='profit'>{cgpt.jobProfit}</span> per hr</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="relative w-full">
                <div className="relative w-full flex bg-[#1B1B1F] rounded-xl">
                    <div className="bg-tab-cards absolute top-0 left-0 w-1/2 h-full bg-white border-[#1B1B1F] border-8 rounded-2xl"></div>
                    <div onClick={(e) => handlePointerEvents("my cards", e)} className="grow shrink basis-0 flex justify-center items-center py-4 mix-blend-difference">
                        <h1 className="text-white font-['Roboto_Mono']">My Cards</h1>
                    </div>
                    <div onClick={(e) => handlePointerEvents("new cards", e)} className="grow shrink basis-0 flex justify-center items-center py-4 mix-blend-difference">
                        <h1 className="text-white font-['Roboto_Mono']">New Cards</h1>
                    </div>
                </div>
            </div>
            <div ref={scrollCards} className="relative w-full h-full overflow-scroll mb-[4rem]">
                <div className="my-cards-div absolute top-0 left-0 w-full  grid grid-cols-2 gap-x-2 gap-y-4 pb-3">
                    {cards.map((card, idx) => {
                        if (card.status == "active" || card.status == "inactive") {
                            return (
                                <div key={`my-card-${idx}`} onClick={(e) => handleButtonEvents("select card", card)} className={` relative w-full aspect-square my-cards origin-center flex justify-center items-center  `}>
                                    <div className={`absolute top-0 left-0 size-full z-[1]`}>
                                        <Cards level={card.requiredlevel} className="size-full" />
                                    </div>
                                    <div className={` ${card.status == "active" ? "visible" : "invisible"} w-full h-full relative z-[2] flex justify-center items-end p-4 bg-card-gradient `}>
                                        <h1 className="px-4 py-2 text-white text-xs relative border-gradient bg-[#111115]">Selected</h1>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
                <div className="new-cards-div absolute top-0 left-0  w-full  grid grid-cols-2 gap-x-2 gap-y-4 pb-3">
                    {cards.map((card, idx) => {
                        if (card.status == null || card.status == "unlocked") {
                            return (
                                <div key={`new-cards-${idx}`} onClick={(e) => handleButtonEvents("open card", card)} className={`relative w-full  aspect-square new-cards origin-center flex justify-center items-center  `}>
                                    <div className="absolute top-0 left-0 size-full">
                                        <Cards level={card.requiredlevel} className="size-full" />
                                    </div>
                                    <div className={` ${card.status == "unlocked" ? cards[idx-1].status === "unlocked" || cards[idx-1].status === null ? "bg-opacity-20" : "bg-opacity-20" : "bg-opacity-50"} w-full h-full relative z-[2] flex justify-center items-end pb-4 bg-[#121212] `}>
                                        <h1 className={`px-4 py-2 text-white text-xs relative  ${card.status == "unlocked" ? cards[idx-1].status === "unlocked" || cards[idx-1].status === null ? "border-light" : "border-gradient" : "border-light"}  bg-[#111115]`}>{ card.status == "unlocked" ? cards[idx-1].status === "unlocked" || cards[idx-1].status === null ? 'Need Prior Card' : 'Claim for ' + card.cost : 'Requires Level ' + card.requiredLevel}</h1>
                                    </div>
                                </div>
                            )
                        }
                    })}




                </div>
            </div>




        </div>
    );
}

export default EarnPage;
